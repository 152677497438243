import { ConversationStep } from "../../interfaces";
import Theme from "../../interfaces/Theme";
import { numberWithCommas } from "../../util";

import * as _ from "lodash";

const BTLRemortgageTrigger = (
  values: any,
  ifTrigger: string,
  elseTrigger: string
) => {
  if (
    values?.mortgage_type === "Buy to Let" ||
    values?.mortgage_type === "Buy to Let Remortgage" ||
    values?.mortgage_type === "Remortgage"
  ) {
    return ifTrigger;
  }

  return elseTrigger;
};

const MortgageApplicantTrigger = (values: any) =>
  BTLRemortgageTrigger(values, "property_value", "buying_new_build");

const NotHTBTrigger = (values: any, value: string) => {
  if (value === "No" || value === "Not Sure") {
    return "property_value";
  }

  return "help_to_buy";
};

const PropertyValueTrigger = (values: any) => {
  if (
    values?.mortgage_type === "Holiday Home" &&
    values?.holiday_let_home_reason === "Rented Out"
  ) {
    return "holiday_let_rental";
  }

  if (
    values?.mortgage_type === "Holiday Let" &&
    (values?.holiday_let_home_reason === "Rented Out" ||
      values?.holiday_let_home_reason === "Bit of Both")
  ) {
    return "holiday_let_rental";
  }

  if (values?.mortgage_type === "Buy to Let") {
    return "monthly_rent";
  }

  if (values?.mortgage_type === "Remortgage") {
    return "current_outstanding_amount";
  }

  return "mortgage_balance";
};

const ModifiedSimpleSteps = (theme: Theme) => {
  return [
    {
      id: "mortgage_greeting",
      type: "bot",
      video:
        //TODO: This is coreco specific update. To be remove once custom flows are implemented.
        theme?.apiKey === "fbb8a34f-0bb0-40c0-a007-7a0f07a68341"
          ? `${process.env.REACT_APP_COMMS_URL}/download/files/public/6b5fdd03-fc9c-49fe-9c45-4cfd29e7a8c1.mp4`
          : `${process.env.REACT_APP_COMMS_URL}/download/files/public/rM775422-6262-479o-0672-60o90r77M952.mp4`,
      trigger: "mortgage_type",
    },
    theme?.apiKey === "e391b696-0125-4fe7-a463-a998667352c9" ||
    theme?.apiKey === "e391b696-0125-4fe7-a463-a998667352c0" ||
    theme?.apiKey === "e391b696-0125-4fe7-a463-a998667352c1"
      ? {
          id: "mortgage_type",
          type: "bot",
          trigger: (values: any) => {
            if (values?.mortgage_type === "Holiday Home") {
              return "holiday_home_mortgage_reason";
            }

            if (values?.mortgage_type === "Holiday Let") {
              return "holiday_let_mortgage_reason";
            }

            return "mortgage_applicant";
          },
          message: (values: any) => {
            if (
              values["mortgage_pre_type"] === "Specialist Mortgages" ||
              values["mortgage_pre_type"] === "Later Life Lending"
            ) {
              return "Choosing the right mortgage for you?";
            }
            return "I will show you the rates and products that you may be eligible for in just 60 seconds, and it won't affect your credit score.";
          },
          options: (values) => {
            if (values["mortgage_pre_type"] === "Buy to let Mortgages") {
              return [
                {
                  label: "Purchase",
                  value: "Purchase",
                  trigger: "mortgage_applicant",
                },
                {
                  label: "Remortgage",
                  value: "Remortgage",
                  trigger: "mortgage_applicant",
                },
                {
                  label: "Holiday Let",
                  value: "Holiday Let",
                  trigger: "holiday_let_mortgage_reason",
                },
              ];
            } else if (values["mortgage_pre_type"] === "Specialist Mortgages") {
              return [
                {
                  label: "Bridging Finance",
                  value: "Bridging Finance",
                  trigger: "[wdbf]_Type of loan",
                },
                {
                  label: "Development Finance",
                  value: "Development Finance",
                  trigger: "[df]_Type of loan",
                },
                {
                  label: "Commercial Finance",
                  value: "Commercial Finance",
                  trigger: "[wdbf]_Type of loan",
                },
                {
                  label: "Expat mortgages",
                  value: "Expat mortgages",
                  trigger: "[em]_Expat mortgage",
                },
              ];
            } else if (values["mortgage_pre_type"] === "Later Life Lending") {
              return [
                {
                  label: "Equity Release",
                  value: "Equity Release",
                  trigger: "[ER]_Condition",
                },
              ];
            }
            return [
              {
                label: "Purchase",
                value: "Purchase",
                trigger: "mortgage_applicant",
              },
              {
                label: "Remortgage",
                value: "Remortgage",
                trigger: "mortgage_applicant",
              },
              {
                label: "First Time Buyer",
                value: "First Time Buyer",
                trigger: "mortgage_applicant",
              },
            ];
          },
        }
      : {
          id: "mortgage_type",
          type: "bot",
          disable: (values) => !_.isEmpty(values[`mortgage_type`]),
          trigger: (values: any) => {
            if (values?.mortgage_type === "Holiday Home") {
              return "holiday_home_mortgage_reason";
            }

            if (values?.mortgage_type === "Holiday Let") {
              return "holiday_let_mortgage_reason";
            }

            return "mortgage_applicant";
          },
          message: "What can I assist you with?",
          options: [
            {
              label: "Holiday Let",
              value: "Holiday Let",
              trigger: "holiday_let_mortgage_reason",
              enable: (values) => !values?.theme?.config?.disableHolidayLet
            },
            {
              label: "Holiday Home",
              value: "Holiday Home",
              trigger: "holiday_home_mortgage_reason",
              enable: (values) => !values?.theme?.config?.disableHolidayHome
            },
            {
              label: "First Home",
              value: "First Home",
              trigger: "mortgage_applicant",
              enable: (values) => !values?.theme?.config?.disableFirstHome
            },
            {
              label: "Move Home",
              value: "Move Home",
              trigger: "mortgage_applicant",
              enable: (values) => !values?.theme?.config?.disableMoveHome
            },
            {
              label: "Remortgage",
              value: "Remortgage",
              trigger: "mortgage_applicant",
              enable: (values) => !values?.theme?.config?.disableRemortgage
            },
            {
              label: "Buy to Let",
              value: "Buy to Let",
              trigger: "mortgage_applicant",
              enable: (values) => !values?.theme?.config?.disableBTL
            },
            {
              label: "Buy to Let Remortgage",
              value: "Buy to Let Remortgage",
              trigger: "mortgage_applicant",
              enable: (values) => !values?.theme?.config?.disableBTLRemortgage
            },
          ],
        },
    {
      id: "mortgage_applicant",
      type: "bot",
      message: "Is this a Single or Joint mortgage?",
      options: [
        {
          label: "Single",
          value: "Single",
          trigger: MortgageApplicantTrigger,
        },
        {
          label: "Joint",
          value: "Joint",
          //TODO: clean this up after flow generation effort is full on
          trigger: (values) =>
            theme.apiKey === "d8eccbf3-bfa9-435b-9735-c91641c5446e"
              ? MortgageApplicantTrigger(values)
              : "joint_earnings",
        },
        {
          label: "Company",
          value: "Company",
          trigger: MortgageApplicantTrigger,
          enable: (values) =>
            values?.mortgage_type === "Buy to Let" ||
            values?.mortgage_type === "Buy to Let Remortgage",
        },
      ],
    },
    {
      id: "joint_earnings",
      type: "bot",
      message: "Do you have any joint earnings?",
      options: [
        {
          label: "Yes",
          value: "Yes",
          trigger: MortgageApplicantTrigger,
        },
        {
          label: "No",
          value: "No",
          trigger: MortgageApplicantTrigger,
        },
      ],
    },
    {
      id: "buying_new_build",
      message: "Will you be buying a new build?",
      type: "bot",

      options: [
        {
          label: "Yes",
          value: "Yes",
          trigger: "help_to_buy",
        },
        {
          label: "No",
          value: "No",
          trigger: NotHTBTrigger,
        },
        {
          label: "Not Sure",
          value: "Not Sure",
          trigger: NotHTBTrigger,
        },
      ],
    },
    {
      id: "help_to_buy",
      message: "Will you be buying through Help to Buy?",
      disable: (values) => !_.isEmpty(values["help_to_buy"]),
      type: "bot",
      trigger: "property_value",
      options: [
        {
          label: "Yes",
          value: "Yes",
          trigger: "property_value",
        },
        {
          label: "No",
          value: "No",
          trigger: "property_value",
        },
      ],
    },
    {
      id: "property_value",
      disable: (values) => !_.isEmpty(values[`property_value`]),
      message:
        theme?.apiKey === `96e28c1c-2c53-4dd4-b479-b1dfaee4313f`
          ? `What is the value of the property? Select an option or type below and click enter if the value isn’t in the list.`
          : `What is the value of the property? ${
              theme?.apiKey === "fbb8a34f-0bb0-40c0-a007-7a0f07a68341"
                ? "(If you're not sure, then give your best guess)"
                : "(If you're not sure, how much do you plan on spending?)"
            }`,
      type: "bot",
      options: (values) => {
        const options = [
          {
            label:
              values?.theme?.apiKey === "8b5d80c6-8ce0-4cc3-b80f-f01629a30c07"
                ? "£ 150,000"
                : "£ 400,000",
            value:
              values?.theme?.apiKey === "8b5d80c6-8ce0-4cc3-b80f-f01629a30c07"
                ? 150000
                : 400000,
            trigger: PropertyValueTrigger,
          },
          {
            label:
              values?.theme?.apiKey === "8b5d80c6-8ce0-4cc3-b80f-f01629a30c07"
                ? "£ 200,000"
                : "£ 500,000",
            value:
              values?.theme?.apiKey === "8b5d80c6-8ce0-4cc3-b80f-f01629a30c07"
                ? 200000
                : 500000,
            trigger: PropertyValueTrigger,
          },
          {
            label:
              values?.theme?.apiKey === "8b5d80c6-8ce0-4cc3-b80f-f01629a30c07"
                ? "£ 300,000"
                : "£ 700,000",
            value:
              values?.theme?.apiKey === "8b5d80c6-8ce0-4cc3-b80f-f01629a30c07"
                ? 300000
                : 700000,
            trigger: PropertyValueTrigger,
          },
        ];

        if (values?.theme?.apiKey === "fbb8a34f-0bb0-40c0-a007-7a0f07a68341") {
          options.push({
            label: "£ 300,000",
            value: 300000,
            trigger: PropertyValueTrigger,
          });
          options.push({
            label: "£ 1m+",
            value: 1000000,
            trigger: PropertyValueTrigger,
          });
        } else if (
          values?.theme?.apiKey === "8b5d80c6-8ce0-4cc3-b80f-f01629a30c07"
        ) {
          options.push({
            label: "£ 500,000",
            value: 500000,
            trigger: PropertyValueTrigger,
          });
        } else {
          options.push({
            label: "£ 600,000",
            value: 600000,
            trigger: PropertyValueTrigger,
          });
        }

        return options.sort((a, b) => a.value - b.value);
      },
      input: {
        type: "number",
        placeholder: "Type here...",
        startAdornment: "£",
      },
      formatter: numberWithCommas,
      trigger: PropertyValueTrigger,
    },
    {
      id: "mortgage_balance",
      message: (values: any) => {
        if (
          (`${values["mortgage_type"]}`).includes("Remortgage") ||
          (`${values["mortgage_pre_type"]}`).includes("Remortgage") ||
          (`${values["holiday_let_mortgage_reason"]}`).includes("Remortgage") ||
          (`${values["holiday_home_mortgage_reason"]}`).includes("Remortgage") 
        ) {
          return "What is your current mortgage balance?";
        }
        return "What sort of deposit are you looking to put down?";
      },
      type: "bot",
      input: {
        type: "number",
        placeholder: "Type here...",
        startAdornment: "£",
        endAdornment: (value: any, values: any) => {
          return `${
            (
              ((parseInt(value) || 0) / parseInt(values?.property_value)) *
              100
            ).toFixed(2) || 0
          }%`;
        },
      },
      options: (values) => {
        return [
          {
            label: `${numberWithCommas(
              (0.1 * parseInt(values?.property_value)).toString()
            )}`,
            value: 0.1 * parseInt(values?.property_value),
            trigger: "mortgage_term",
          },
          {
            label: `${numberWithCommas(
              (0.2 * parseInt(values?.property_value)).toString()
            )}`,
            value: 0.2 * parseInt(values?.property_value),
            trigger: "mortgage_term",
          },
          {
            label: `${numberWithCommas(
              (0.3 * parseInt(values?.property_value)).toString()
            )}`,
            value: 0.3 * parseInt(values?.property_value),
            trigger: "mortgage_term",
          },
          {
            label: `${numberWithCommas(
              (0.4 * parseInt(values?.property_value)).toString()
            )}`,
            value: 0.4 * parseInt(values?.property_value),
            trigger: "mortgage_term",
          },
        ];
      },
      formatter: numberWithCommas,
      trigger: "mortgage_term",
      validator: (value: any) => {
        if (isNaN(value)) {
          return `Please enter a number`;
        }

        return true;
      },
    },
    {
      id: "current_outstanding_amount",
      message: (values: any) => {
        if (
          (`${values["mortgage_type"]}`).includes("Remortgage") ||
          (`${values["mortgage_pre_type"]}`).includes("Remortgage") ||
          (`${values["holiday_let_mortgage_reason"]}`).includes("Remortgage") ||
          (`${values["holiday_home_mortgage_reason"]}`).includes("Remortgage")  
        ) {
          return "What is your current mortgage balance?";
        }
        return "What is your current outstanding mortgage amount?";
      },
      type: "bot", //
      custom_value: (value) => {
        return {
          key: "mortgage_balance",
          value,
        };
      },
      input: {
        type: "number",
        placeholder: "Type here...",
        startAdornment: "£",
        endAdornment: (value: any, values: any) => {
          return `${
            (
              ((parseInt(value) || 0) / parseInt(values?.property_value)) *
              100
            ).toFixed(2) || 0
          }%`;
        },
      },
      options: (values) => {
        return [
          {
            label: `${numberWithCommas(
              (0.1 * parseInt(values?.property_value)).toString()
            )}`,
            value: 0.1 * parseInt(values?.property_value),
            trigger: "mortgage_term",
          },
          {
            label: `${numberWithCommas(
              (0.2 * parseInt(values?.property_value)).toString()
            )}`,
            value: 0.2 * parseInt(values?.property_value),
            trigger: "mortgage_term",
          },
          {
            label: `${numberWithCommas(
              (0.3 * parseInt(values?.property_value)).toString()
            )}`,
            value: 0.3 * parseInt(values?.property_value),
            trigger: "mortgage_term",
          },
          {
            label: `${numberWithCommas(
              (0.4 * parseInt(values?.property_value)).toString()
            )}`,
            value: 0.4 * parseInt(values?.property_value),
            trigger: "mortgage_term",
          },
        ];
      },
      formatter: numberWithCommas,
      trigger: "mortgage_term",
      validator: (value: any) => {
        if (isNaN(value)) {
          return `Please enter a number`;
        }

        return true;
      },
    },
    {
      id: "mortgage_term",
      message: "How long is the mortgage for? (Term in years)",
      type: "bot",
      input: {
        type: "number",
        placeholder: "Type here...",
      },
      options: [
        {
          label: "20",
          value: 20,
          trigger: "applicant1_annualSalary",
        },
        {
          label: "22",
          value: 22,
          trigger: "applicant1_annualSalary",
        },
        {
          label: "25",
          value: 25,
          trigger: "applicant1_annualSalary",
        },
        {
          label: "27",
          value: 27,
          trigger: "applicant1_annualSalary",
        },
        {
          label: "30",
          value: 30,
          trigger: "applicant1_annualSalary",
        },
        {
          label: "35",
          value: 35,
          trigger: "applicant1_annualSalary",
        },
      ],
      trigger: "applicant1_annualSalary",
    },
    {
      id: `applicant1_annualSalary`,
      type: "bot",
      message: `What are your earnings before tax? (Include the annual salary and any extras; like bonuses, overtime or commission. Add Both Applicants income if applicable)`,
      input: {
        type: "number",
        placeholder: "Type here...",
        startAdornment: "£",
      },
      formatter: numberWithCommas,
      trigger: `applicant1_firstName`,
      validator: (value: any) => {
        if (isNaN(value)) {
          return "Please enter a valid input";
        }

        return true;
      },
    },
    {
      id: `applicant1_firstName`,
      type: "bot",
      // video:
      //   //TODO: This is a coreco specific update. To be remove once custom flows are implemented.
      //   theme?.apiKey === "fbb8a34f-0bb0-40c0-a007-7a0f07a68341"
      //     ? `${process.env.REACT_APP_COMMS_URL}/download/files/public/8a76c27d-5cc3-41c0-a841-93bc7ada13be.mp4`
      //     : `${process.env.REACT_APP_COMMS_URL}/download/files/public/r8360r0r-7886-4685-34o7-r86mr60r7878.mp4`,
      disable: (values) => !_.isEmpty(values?.applicant1_firstName),
      input: {
        type: "text",
        placeholder: "Type here...",
      },
      message: `What is your first name?`,
      trigger: `applicant1_lastName`,
    },
    {
      id: `applicant1_lastName`,
      type: "bot",
      disable: (values) => !_.isEmpty(values?.applicant1_lastName),
      input: {
        type: "text",
        placeholder: "Type here...",
      },
      message: `What is your last name?`,

      trigger: "applicant1_email",
    },
    {
      id: `applicant1_email`,
      type: "bot",
      disable: (values) => !_.isEmpty(values?.applicant1_email),
      input: {
        type: "email",
        placeholder: "Type here...",
      },

      message: `What is your email?`,
      trigger: "applicant1_contactNumber",
    },
    {
      id: `applicant1_contactNumber`,
      type: "bot",
      disable: (values) => !_.isEmpty(values?.applicant1_contactNumber),
      input: {
        type: "text",
        placeholder: "Type here...",
      },

      message: `What is your contact number?`,
      trigger: (values: any) => {
        if (
          values?.theme?.apiKey === "d83355c3-53dd-4b14-9c7d-fba365ff0819" &&
          values["How can I help?"] === "High Networth Services"
        ) {
          return "flow_end_v2";
        }
        if (
          values?.theme?.apiKey === "d83355c3-53dd-4b14-9c7d-fba365ff0819" &&
          (values["mortgage_type"] === "Bridging Finance" ||
            values["mortgage_type"] === "Commercial Finance")
        ) {
          return "flow_end_v2";
        }

        if (
          values?.theme?.version === "protection" ||
          values?.mortgage_type === "Protection Review" ||
          values["How can I help?"] === "Protection Review"
        ) {
          return "insurance_quote";
        }

        if (
          values?.theme?.apiKey === "2a6d4d16-cedb-45d4-9d11-bf5755963822" ||
          values?.high_networth_services ===
            "Book Your Personal Consultation" ||
          values?.how_can_we_help === "Book an appointment" ||
          values?.mortgage_balance === undefined ||
          values?.mortgage_balance === null ||
          values?.property_value === undefined ||
          values?.property_value === null
        ) {
          return "talk_with_advisor";
        }

        return "how_can_i_help_you_next";
      },
    },
    {
      id: `how_can_i_help_you_next`,
      type: "bot",
      message: `Thanks for that information. How can I help you next?`,
      options: [
        {
          label: "Book an appointment with an advisor",
          value: "book_appointment",
          trigger: () => {
            return "talk_with_advisor_sendnewlead"
          },
        },
        {
          label: "Show me an example mortgage illustration",
          value: "show_illustration",
          trigger: () => {
            return "illustration_page_sendnewlead"
          },
        },
      ],
    },
  ] as ConversationStep[];
};

export default ModifiedSimpleSteps;
